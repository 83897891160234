import {
  Box,
  Container,
  Heading,
  Stack,
  Image,
  Center,
  ListItem,
  List,
  HStack,
  Text,
  VStack,
  useMediaQuery,
} from "@chakra-ui/react";
import { TitleWithDivider } from "./shared/TitleWithLine";

/**
 * 料金表
 */
const Place = () => {
  // モバイル端末用にUIを出し分けたいため、ビューポート幅が640ピクセルより大きいか？の判定
  const [isLargerThan640] = useMediaQuery("(min-width: 640px)");

  // 支払い方法の文言
  const contents = {
    taimen: {
      title: "対面配達（集金）",
      description:
        "ご指定の時間にお届けできない場合がありますのでご了承下さい。",
    },
    okihaiGojitu: {
      title: "置き配（後日集金）",
      description: "請求書を荷物に貼りつけ、後日集金致します。",
    },
    okihaiFurikomi: {
      title: "置き配（振込）",
      description:
        "請求書を荷物に貼りつけますので下記の口座へお振込みお願い致します。",
      memo: "※振込手数料はお客様のご負担となります",
    },
  };

  return (
    <Box w={"100%"}>
      <Container maxW={"5xl"} px={{ base: 4, sm: 8, md: 16, lg: 0 }}>
        <Stack align={"top"} py={{ base: 8, md: 16 }}>
          {/* TODO: このHeadingを直す */}
          <Heading
            fontWeight={600}
            fontSize={{ base: "3xl", sm: "4xl", md: "6xl" }}
            lineHeight={"50%"}
          >
            <Box paddingBottom={4}>
              <Heading
                as={"h1"}
                fontSize={"3xl"}
                paddingBottom={"8"}
                textAlign={"center"}
              >
                基本料金
              </Heading>
              <Box textDecoration="none" _hover={{ textDecoration: "none" }}>
                <Center>
                  <Image
                    src={
                      "https://firebasestorage.googleapis.com/v0/b/yamaneko-takuhai.appspot.com/o/price_2023-12-02.jpg?alt=media&token=8203aaf7-1198-44ef-9a08-c6c1f4b83e38"
                    }
                    alt="料金表"
                    objectFit="contain"
                  />
                </Center>
              </Box>
            </Box>
          </Heading>

          <Text pb={8} fontSize={{ base: "xs", md: "sm", lg: "xl" }}>
            ※ 配達依頼時に支払い方法を下記より選択しお伝えください。
          </Text>

          {/* 配達料金支払い方法 */}
          <TitleWithDivider title="配達料金支払い方法"></TitleWithDivider>

          <Box fontSize={{ md: "sm", lg: "xl" }}>
            {/* モバイル端末のみUIを出し分けたいため、条件分岐してる */}
            {isLargerThan640 ? (
              // 画面が大きい場合
              <HStack ps={4} pb={8} alignItems={"start"}>
                <List spacing={2}>
                  <ListItem>① {contents.taimen.title}</ListItem>
                  <ListItem>② {contents.okihaiGojitu.title}</ListItem>
                  <ListItem>③ {contents.okihaiFurikomi.title}</ListItem>
                </List>

                <List spacing={2} ps={2}>
                  <ListItem>{contents.taimen.description}</ListItem>
                  <ListItem>{contents.okihaiGojitu.description}</ListItem>
                  <ListItem>
                    {contents.okihaiFurikomi.description}
                    <br />
                    {contents.okihaiFurikomi.memo}
                  </ListItem>
                </List>
              </HStack>
            ) : (
              // 画面が狭いモバイル端末など
              <VStack align={"start"} ps={2} pb={4}>
                <HStack align={"start"}>
                  <Text>①</Text>
                  <Text>
                    <b>{contents.taimen.title}</b>
                    <br />
                    {contents.taimen.description}
                  </Text>
                </HStack>
                <HStack align={"start"}>
                  <Text>②</Text>
                  <VStack align={"start"}>
                    <Text>
                      <b>{contents.okihaiGojitu.title}</b>
                      <br />
                      {contents.okihaiGojitu.description}
                    </Text>
                  </VStack>
                </HStack>
                <HStack align={"start"}>
                  <Text>③</Text>
                  <VStack align={"start"}>
                    <Text>
                      <b>{contents.okihaiFurikomi.title}</b>
                      <br />
                      {contents.okihaiFurikomi.description}
                      <br />
                      {contents.okihaiFurikomi.memo}
                    </Text>
                  </VStack>
                </HStack>
              </VStack>
            )}

            {/* 振込先 */}
            <TitleWithDivider title="振込先" />
            <Heading as="h4" fontSize={"xl"} pt={2}>
              ゆうちょ銀行
            </Heading>
            <List spacing={2} ps={2} pt={2}>
              {/* TODO: 幅を揃えて見やすくしたい */}
              <ListItem>店名 七〇八</ListItem>
              <ListItem>店番 708</ListItem>
              <ListItem>口座 普通預金</ListItem>
              <ListItem>口座番号 2166378</ListItem>
              <ListItem>名義 イケムラ ケンジ</ListItem>
            </List>
            <Heading as="h4" fontSize={"xl"} pt={8}>
              ゆうちょ銀行に口座があり郵便局ATMから送金される方
            </Heading>
            <List spacing={2} ps={2} pt={2}>
              <ListItem>ゆうちょ銀行</ListItem>
              <ListItem>記号 17000</ListItem>
              <ListItem>
                <Text decoration={"none"}>番号 21663781</Text>
              </ListItem>
            </List>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default Place;
