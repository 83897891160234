import {
  Box,
  Container,
  Heading,
  Stack,
  Text,
  Image,
  Center,
} from "@chakra-ui/react";

const Service = () => {
  return (
    <Box w={"100%"} backgroundColor={"#E0F5E8"}>
      <Center>
        <Container
          maxW={"5xl"}
          display={{ md: "flex" }}
          mx={{ base: 2, sm: 8, md: 8, lg: 0 }}
        >
          <Stack align={"top"} py={{ base: 8, md: 16 }}>
            <Box paddingBottom={4}>
              <Heading as={"h1"} fontSize={"3xl"} textAlign={"center"}>
                やまねこ宅配のサービス
              </Heading>
            </Box>

            <Box
              marginTop={{ base: "1", sm: "5" }}
              display="flex"
              flexDirection={{ base: "column", sm: "row" }}
              justifyContent="space-between"
            >
              <Box
                display="flex"
                flex="1"
                marginRight="3"
                position="relative"
                alignItems="center"
              >
                <Box
                  width={{ base: "100%", sm: "85%" }}
                  zIndex="2"
                  marginLeft={{ base: "0", sm: "5%" }}
                  marginTop="5%"
                >
                  <Box
                    textDecoration="none"
                    _hover={{ textDecoration: "none" }}
                  >
                    <Image
                      borderRadius="lg"
                      src={
                        "https://firebasestorage.googleapis.com/v0/b/yamaneko-takuhai.appspot.com/o/service_car.jpg?alt=media&token=3d291a6d-1062-4871-975f-541f81a02f79&_gl=1*1e9717z*_ga*MTk2NzkzODA0OS4xNjYwOTc1OTkw*_ga_CW55HF8NVT*MTY5ODkyNDAwOC41NS4xLjE2OTg5MjQ0MDguMzguMC4w"
                      }
                      alt="some good alt text"
                      objectFit="contain"
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                display="flex"
                flex="1"
                flexDirection="column"
                justifyContent="center"
                marginTop={{ base: "16", sm: "0" }}
              >
                <Heading
                  fontSize={{ base: "2xl", sm: "2xl", md: "2xl" }}
                  marginTop="1"
                  textAlign={"center"}
                  marginBottom={4}
                >
                  港の荷物を引き取ります
                </Heading>
                <Text
                  as="p"
                  marginTop="2"
                  color="gray.700"
                  align={"start"}
                  // fontSize="lg"
                  fontSize={{ base: "lg", lg: "xl" }}
                  lineHeight={"150%"}
                >
                  貨物船で入ってきた荷物を西表西部へ配達いたします！
                  <br />
                  海が時化（しけ）、貨物船が上原欠航した場合でも、荷物を大原より配送いたします。
                  <br />
                  ※対応曜日あり
                  <br />
                  <br />
                  もちろん上原港より配送も可能です。
                  <br />
                  ※石垣より西表島までの貨物船運賃はお客様のご負担となります。
                  <br />
                  <br />
                  ※貨物船運賃(石垣島→西表島)立替えサービスもあります。弊社にていったん貨物運賃を立て替えて、配達料金と貨物運賃を合計した請求も可能となっております。
                </Text>
              </Box>
            </Box>
          </Stack>
        </Container>
      </Center>
    </Box>
  );
};

export default Service;
