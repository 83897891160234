import { VStack } from "@chakra-ui/react";
import { TitleWithDivider } from "../../shared/TitleWithLine";
import { LargeContent } from "./LargeContent";

/**
 * 対応時期や曜日について 大きい画面用（タブレットやPC）
 */
export const WorkingDayContentLarge = () => {
  return (
    <VStack align={"top"}>
      <TitleWithDivider title="9月〜4月" />

      <LargeContent
        name="かりゆし（八重山観光フェリー貨物船）"
        workingDays="月・火・水・木・金・※土"
        departure="石垣発 9:00"
        warning="※土曜日のみ上原行きが欠航した場合、運休となる場合がありますので、事前に貨物事務所へ確認をお願い致します。"
      />

      <TitleWithDivider title="5月～8月" />

      <LargeContent
        name="ぱいかじ（安栄観光貨物船）"
        workingDays="月・水・金"
        departure="石垣発 8:30"
      />

      <LargeContent
        name="かりゆし（八重山観光フェリー貨物船）"
        workingDays="火・木・土"
        departure="石垣発 9:00"
      />
    </VStack>
  );
};
